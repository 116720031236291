<template>
	<div>
		<v-avatar size="160">
			<v-img :src="user.imgURL" />
		</v-avatar>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'UserPicture',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters({
			user: 'users/user'
		})
	}
}
</script>
